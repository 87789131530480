<template>
  <div class="p-3">
    <div class="d-flex flex-row align-items-start flex-wrap">
      <select
        class="form-control form-control-sm shadow-none col-md-2 col-sm-4 m-1"
        v-model="option"
        @change="change_filter"
      >
        <option value="cod">по коду</option>
        <option value="art">по артикулу</option>
        <option value="name">по нименованию</option>
        <option value="instr">по инструменту</option>
        <option value="sc">по СЦ</option>
      </select>
      <select
        class="form-control form-control-sm shadow-none col-md-2 col-sm-4 m-1"
        v-model="year"
        @change="change_filter"
      >
        <option value="all">все</option>
        <option v-for="(item, idx) in years()" :key="idx" :value="item">{{
          item
        }}</option>
      </select>
      <div class=" m-1  col-md-5 col-sm-4">
        <div class="cont">
          <input
            type="text"
            class="form-control  form-control-sm change-user-input shadow-none"
            v-model="search_text"
          />
          <div v-if="option === 'instr'" class="hh w-100">
            <table class="table table-sm table-hover">
              <tbody>
                <tr
                  v-for="item in ll"
                  :key="item.cod"
                  @click="selectted_instr(item)"
                  class="cursor"
                >
                  <td>{{ item.cod }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.count }}шт.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="option === 'sc'" class="hh w-100">
            <table class="table table-sm table-hover">
              <tbody>
                <tr
                  v-for="(item, idx) in ul"
                  :key="idx + 'u'"
                  @click="selectted_user(item)"
                  class="cursor"
                >
                  <td>{{ item.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        class="btn btn-sm btn-outline-primary shadow-none m-1"
        @click="search"
      >
        найти
      </button>
    </div>
    <div v-if="list" class="overflow-auto">
      <table class="table table-sm table-hover ggg">
        <tbody v-if="summ">
          <tr>
            <td colspan="4"></td>
            <td>{{ summ.summ_pcs }}шт.</td>
            <td class="text-nowrap">{{ summ.summ_price }}р.</td>
          </tr>
        </tbody>
        <tbody class="w-100">
          <template v-for="(item, idx) in list">
            <tr :key="idx + 'list'" @click="is_visible_tr(idx)" class="cursor">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.cod }}</td>
              <td>{{ item.art }}</td>
              <td>{{ item.items_count }}шт.</td>
              <td class="text-nowrap">{{ item.summ_price_zap }}р.</td>
            </tr>
            <tr :key="idx + 'list2'" v-if="visible_tr === idx" class="cursor">
              <td colspan="6">
                <div class="hh">
                  <table class="table table-sm t2">
                    <tr
                      v-for="(item2, idx2) in item.items"
                      :key="idx2 + 'ddd'"
                      @click="go_repair(item2.remont_id)"
                      :class="selected === item2.remont_id && 'text-primary'"
                    >
                      <td>{{ idx2 + 1 }}</td>
                      <td class="text-nowrap">{{ item2.repair_number }}</td>
                      <td class="text-nowrap">{{ item2.receiptNumber }}</td>
                      <td class="text-nowrap">{{ item2.user }}</td>
                      <td class="text-nowrap">{{ item2.name_instr }}</td>
                      <td class="text-nowrap">{{ item2.date_addFormat }}</td>
                      <td class="text-nowrap">{{ item2.serialNumberGroup }}</td>
                      <td class="text-nowrap">{{ item2.serialNumber }}</td>
                      <td class="text-nowrap">{{ item2.dateSaleFormat }}</td>
                      <td class="text-nowrap">{{ item2.date_addFormat }}</td>
                      <td>{{ item2.defect }}</td>
                      <td class="text-nowrap">{{ item2.seller }}</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <Loader v-if="loader" />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import Loader from "@/components/Loader";
export default {
  name: "SearchZapInRepairs",
  components: { Loader },
  computed: {
    ll: function() {
      const list = this.instr_list;
      return list.filter(item =>
        item.name.toLowerCase().includes(this.search_text.toLowerCase())
      );
    },
    ul: function() {
      const list = this.users_list;
      return list.filter(item =>
        item.name.toLowerCase().includes(this.search_text.toLowerCase())
      );
    }
  },
  data: () => ({
    option: "cod",
    search_text: "",
    year: new Date().getFullYear(),
    list: null,
    summ: null,
    visible_tr: null,
    selected: null,
    loader: false,
    instr_list: [],
    users_list: [],
    selected_user_id: null,
    cod: null
  }),
  mounted() {},
  methods: {
    search() {
      let url = "/search_zap_in_repairs/get_data.php";
      if (this.option === "instr") {
        url = "/search_zap_in_repairs/instr_get_data.php";
      }
      if (this.option === "sc") {
        url = "/search_zap_in_repairs/users_get_data.php";
      }
      this.loader = true;
      HTTPGET({
        url: url,
        params: {
          type: this.option,
          search_text: this.search_text,
          year: this.year,
          cod: this.cod,
          user_id: this.selected_user_id
        }
      })
        .then(response => {
          this.list = response.data.list;
          this.summ = response.data.summ;
        })
        .then(() => (this.loader = false));
    },
    years() {
      const year_start = new Date().getFullYear();
      let arr = [];
      for (let i = year_start; i >= 2021; i--) {
        arr.push(i);
      }
      return arr;
    },
    is_visible_tr(idx) {
      if (this.visible_tr === idx) {
        this.visible_tr = null;
        this.selected = null;
      } else {
        this.visible_tr = idx;
      }
    },
    go_repair(id) {
      window.open(`/repairs/repair/${id}`, "_blank");
      this.selected = id;
    },
    change_filter() {
      this.search_text = "";
      this.list = null;
      this.summ = null;
      this.visible_tr = null;
      this.selected = null;
      this.instr_list = [];
      this.cod = null;
      this.selected_user_id = null;
      if (this.option === "instr") {
        this.loader = true;
        HTTPGET({
          url: "/search_zap_in_repairs/get_instr_list.php",
          params: {
            year: this.year
          }
        })
          .then(response => (this.instr_list = response.data))
          .then(() => (this.loader = false));
      }
      if (this.option === "sc") {
        this.loader = true;
        HTTPGET({
          url: "/search_zap_in_repairs/get_users_list.php",
          params: {
            year: this.year
          }
        })
          .then(response => {
            this.users_list = response.data;
          })
          .then(() => (this.loader = false));
      }
    },
    selectted_instr(item) {
      this.cod = item.cod;
      this.search_text = item.name;
      console.log(item.cod);
    },
    selectted_user(item) {
      this.selected_user_id = item.id;
      this.search_text = item.name;
      console.log(item);
    }
  }
};
</script>
<style scoped>
td {
  font-size: 0.7em;
  vertical-align: middle;
}
.t2 {
  font-size: 1.2em;
}
.cursor {
  cursor: pointer;
}
.hh {
  max-height: 400px;
  overflow: auto;
}
.ggg {
  overflow-x: auto;
}
.cursor {
  cursor: pointer;
}
</style>
